import React from "react";
import PartnerCss from '../css/Partner.css';
import ImagenPartner1 from '../img/PARTNER1-IMAGEN.jpg';
import ImagenPartner2 from '../img/PROXIMAMENTE-IMAGEN.jpg';
import { FcCalendar } from "react-icons/fc";
import ImagenDeFondo from '../img/posible-fondo1.jpg';

function Parther() {
  return (
    <div className="container-partner-principal" style={{ backgroundColor: '#051C2E', display: 'block', width: 'auto' }}>

      <div className="contenedor-svg-partner-superior">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="rgb(237, 236, 233)" fill-opacity="1" d="M0,192L180,256L360,128L540,256L720,256L900,128L1080,160L1260,320L1440,288L1440,0L1260,0L1080,0L900,0L720,0L540,0L360,0L180,0L0,0Z"></path>
        </svg>

      </div>

      <div className="header">
        <h2 className="titulo-partner">PARTNERS</h2>
      </div>


      <div className="card-container-partner">
        <div className="card-partner  rotate-scale-down-ver">
          <div className="header-partner">
            <div>
              <img className="imagen-partner" src={ImagenPartner1} />
            </div>
            <div className="date-partner">
              <span><FcCalendar style={{ fontSize: '1.2rem', marginBottom: '-0.4rem' }} /> Septiembre 25, 2023</span>
            </div>
          </div>
          <div className="info-partner">
            <p rel="noopener noreferrer" href="#" className="block-partner">
              <span className="title-partner" >SINOMALY</span>
            </p>
            <p className="description-partner">Fundada en Liyang Jiuangsu el 2007, SINAMALY es un proveedor internacional de servicios de ingeniería integrado con desarrollo, diseño, fabricación, suministro de sistemas en proyectos completos y consultoría técnica.</p>
          </div>
        </div>
        <div className="card-partner  rotate-scale-down-ver">
          <div className="header-partner">
            <div>
              <img className="imagen-partner" src={ImagenPartner2} />
            </div>
            <div className="date-partner">
              <span><FcCalendar style={{ fontSize: '1.2rem', marginBottom: '-0.4rem' }} /><del> Septiembre 00, 2000 </del></span>
            </div>
          </div>
          <div className="info-partner">
            <p rel="noopener noreferrer" href="#" className="block-partner">
              <del><span className="title-partner">PROXIMAMENTE</span></del>
            </p>
            <p className="description-partner">
              <del>Lorem otrotortrtr gretreterter gregregipsum dolor sit amet consectetur, um dolor sit amet consectetur ter gregregipsum dolor sit amet consectetur, um dolor sit amet consectet adipisicing elit. Repellat, excepturi. Lorem ipsum dolor sit</del>
            </p>

          </div>
        </div>
      </div>

      {/* <div className="contenedor-inferior-svg" style={{ width: '100%' }}>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="rgb(237, 236, 233)" fill-opacity="1" d="M0,192L360,64L720,96L1080,96L1440,0L1440,320L1080,320L720,320L360,320L0,320Z"></path>
        </svg>

      </div> */}

    </div>

  );
}

export default Parther;






