
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';

import iconComputer from '../img/icon-programador.png';
import CSS from '../css/Cards.css';

import ServidoresImagen from '../img/SERVIDORES-IMAGEN.jpg';
import ObjetivosImagen from '../img/NASA-IMAGEN.jpeg';
import IndutriaImagen from '../img/MINERA-INDUSTRIAL.jpeg';


import { useMediaQuery } from '@mui/material';

function CustomCard(props) {
  const { title, text, image, imageAlt, isTextOnRight } = props;
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{ flexGrow: 1 }} className='color-fondo-caja '>
      <Grid container>
        <Grid item xs={1} sm={1} className='borrar'></Grid>
        <Grid item xs={12} sm={10} className='contenedor-imagen-tarjetas'>
          <Grid container className='separador-top-carrousel'>
            {isSmallScreen ? (
              <>
                <Grid item xs={12} sm={6} className='texto-cards'>
                  <div className='separacion-texto'>
                    <h2 className='tamanio-titulo'>{title}</h2>
                    <p className='tamanio-parrafo-card-inicio'>{text}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className='contenido-tarjeta' style={{ margin: '0% 6%' }}>
                    <Paper square elevation={0} style={{ backgroundColor: '#051C2E' }}>
                      <div style={{ width: '100%', height: '100%' }} >
                        <img
                          className='imagen'
                          src={image}
                          alt={imageAlt}
                          style={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                          }}
                        />
                      </div>
                    </Paper>
                  </div>
                </Grid>
              </>
            ) : (
              <>
                {isTextOnRight ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <div className='contenido-tarjeta' style={{ margin: '0% 6%', marginTop: '-2rem' }}>
                        <Paper square elevation={0} style={{ backgroundColor: '#051C2E' }}>
                          <div style={{ width: '100%', height: '100%' }} >
                            <img
                              className='imagen'
                              src={image}
                              alt={imageAlt}
                              style={{
                                width: '100%',
                                height: 'auto',
                                display: 'block',
                                borderRadius: '0.5rem'
                              }}
                            />
                          </div>
                        </Paper>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className='texto-cards'>
                      <div >
                        <h2 className='tamanio-titulo'>{title}</h2>
                        <p className='tamanio-parrafo-card-inicio'>{text}</p>
                      </div>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6} className='texto-cards'>
                      <div >
                        <h2 className='tamanio-titulo'>{title}</h2>
                        <p className='tamanio-parrafo-card-inicio'>{text}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className='contenido-tarjeta' style={{ margin: '0 6%' }}>
                        <Paper square elevation={0} style={{ backgroundColor: '#051C2E' }}>
                          <div style={{ height: '100%' }}>
                            <img
                              className='imagen'
                              src={image}
                              alt={imageAlt}
                              style={{
                                width: '100%',
                                height: 'auto',
                                display: 'block',
                                borderRadius: '0.5rem',
                                marginBottom: '3rem'
                              }}
                            />
                          </div>
                        </Paper>
                      </div>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1} className='borrar'></Grid>
      </Grid>
    </Box>
  );
}



function Cards() {
  return (
    <div>
      <CustomCard
        title="LA TECNOLOGIA ES EL FUTURO"
        text="En un mundo en constante evolución, donde la tecnología avanza a pasos agigantados, las empresas se enfrentan a la necesidad de adaptarse rápidamente para seguir siendo competitivas. En este panorama, emerge una empresa líder en el desarrollo de software: ABTEC CHILE."
        image={ServidoresImagen}
        imageAlt="Servidores"
        isTextOnRight={false} // Texto a la izquierda
      />

      <CustomCard
        title="UNA EMPRESA DE INNOVACIÓN INDUSTRIAL"
        text="Somos una empresa basada en la tecnología y la información, así como en la transformación digital para las diferentes organizaciones industriales."
        image={IndutriaImagen}
        imageAlt="Industrial"
        isTextOnRight={true} // Texto a la derecha
        className="segunda-tarjeta"
      />

      <CustomCard
        title="NUESTROS OBJETIVOS"
        text="Implementamos soluciones tecnológicas cuyo objetivo es darle solución a la unificación de la información, haciendo un cambio tecnológico completo para las organizaciones, de manera de llevarla a la nueva era digital, Industria 4.0"
        image={ObjetivosImagen}
        imageAlt="Objetivos"
        isTextOnRight={false} // Texto a la izquierda
      />
    </div>
  );
}

export default Cards;