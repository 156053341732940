import React from 'react';
import Carrousel from '../components/Carrousel';
import Cards from '../components/Cards';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar'
import Parther from '../components/Partner';

function Home() {
  return (
    <div>
      <Carrousel />
      <Cards />
      <Parther />
      <Footer />
    </div>
  );
}

export default Home;
