import React from 'react';
import ProductosHRS from '../components/ProductoHRS';
import Productos from '../components/Productos';
import Footer from '../components/Footer';
import ImagenHRS from '../img/IMAGEN-ACTIVIDADES-SERVICIOS.jpg';

function SeccionProductoHRS() {
  return (
    <div>
      <Productos
        imagenSrc={ImagenHRS}
        titulo="Sistema Historiador de Reportabilidad"
        descripcion="Toma las señales de Sistemas de Control (PLC/DCS) y otros sistemas, generando historización de ellos, agregando a los datos un rango de tiempo, para ser acumulada la información en una base de datos, para luego ser visualizados en indicadores de los distintos reportes automatizados, para destintas plataformas como: Excel, Power BI/Grafana, para la toma de desición."
      />
    </div>
  );
}

export default SeccionProductoHRS;
