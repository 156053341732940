import React from "react";
import ProductosCss from '../css/Productos.css';

function Productos({ imagenSrc, titulo, descripcion }) {
  return (
    <div className="courses-containerr" style={{ backgroundColor: '#05253e', paddingTop: '3rem' }}>
      <div className="coursee">
        <div className="course-leftt">
          <div className="course-previeww">
            <img src={imagenSrc} alt={`Imagen ${titulo}`} className="centered-image" />
          </div>
        </div>
        <div className="course-rightt">
          <div className="course-infoo">
            <h3 className="titulo-parrado-servicio-hrs">{titulo}</h3>
            <p className='parrafo-card-servicioss'>
              {descripcion}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Productos;
