import React from "react";
import NoticiasCss from '../css/Noticias.css';
import { AiOutlineUser, AiOutlineCalendar } from "react-icons/ai";
import ImagenNoticia from '../img/ESCAVADORA-IMAGEN.jpeg';

function CustomCard({ title, date, author, content, imageSrc, imageAlt }) {
  return (
    <div className="contendor-principal-noticias" style={{ backgroundColor: '#05253e' }}>
      <div className="inner-box">
        <div className="image-box">
          <figure className="image">
            <a><img width="768" height="383" src={imageSrc} alt={imageAlt} sizes="(max-width: 768px) 100vw, 768px" /></a>
          </figure>
        </div>
        <div className="contenedor-texto-noticia">

          <ul className="post-info clearfix">
            <li>
              <AiOutlineUser className="icon-noticias" /> <a>{author}</a>
            </li>
            <li>
              <AiOutlineCalendar className="icon-noticias" />
              <span className="entry-date published">{date}</span>
            </li>
          </ul>

          <h3>{title}</h3>
          <p>{content}</p>

        </div>
      </div>
    </div>
  );
}


function Noticias() {
  // Lista de noticias
  const noticias = [
    {
      title: "TITULO NOTICIA 1",
      date: "  Diciembre 21, 2023",
      author: "ABTEC CHILE",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu",
      imageSrc: ImagenNoticia,
      imageAlt: "Alt de la imagen 1",
    },
    {
      title: "TITULO NOTICIA 1",
      date: "  Diciembre 21, 2023",
      author: "ABTEC CHILE",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu",
      imageSrc: ImagenNoticia,
      imageAlt: "Alt de la imagen 1",
    }

  ];

  return (
    <div className="App">
      {noticias.map((noticia, index) => (
        <CustomCard
          key={index} 
          title={noticia.title}
          date={noticia.date}
          author={noticia.author}
          content={noticia.content}
          imageSrc={noticia.imageSrc}
          imageAlt={noticia.imageAlt}
        />
      ))}
    </div>
  );
}

export default Noticias;