import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { PiInstagramLogoLight, PiLinkedinLogo, PiWhatsappLogoLight, PiPhonePlusBold } from "react-icons/pi";

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import { useMediaQuery } from '@mui/material';

import CarrouselCSS from '../css/Carrousel.css';
import ComputadoraImagen from '../img/USUARIO.jpeg';
import BarcosImagen from '../img/BARCOS-IMAGEN.jpeg';
import PanelesSolares from '../img/PANEL-SOLAR-IMAGEN.jpg';
import IndustrialImagen from '../img/ESCAVADORA-IMAGEN.jpeg';
import HidrogenoImagen from '../img/HIDROGENO-VERDE-IMAGEN.jpeg';
import EnergiaEolicaImagen from '../img/EOLICA-IMAGEN.jpg';



const actions = [
  { name: 'Instagram', icon: <PiInstagramLogoLight className='icon-instagram' />, link: 'https://www.instagram.com/abtecchile/' },
  { name: 'LinkedIn', icon: <PiLinkedinLogo className='icon-linkedin' />, link: 'https://www.linkedin.com/company/abtec-chile-ingenieria-spa/' },
  { name: 'WhatsApp', icon: <PiWhatsappLogoLight className='icon-whatsapp' />, link: 'https://api.whatsapp.com/send?phone=56968252294' },
];

const images = [
  {
    label: 'imagen-carrousel1',
    img: EnergiaEolicaImagen,
  },
  {
    label: 'imagen-carrousel2',
    img: HidrogenoImagen,
  },
  {
    label: 'imagen-carrousel3',
    img: BarcosImagen,
  },
  {
    label: 'imagen-carrousel4',
    img: PanelesSolares,
  },
  {
    label: 'imagen-carrousel5',
    img: IndustrialImagen,
  },
  {
    label: 'imagen-carrousel6',
    img: ComputadoraImagen,
  }
];

function Carrousel() {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [activeStep, setActiveStep] = useState(0);
  const [socialMediaOpen, setSocialMediaOpen] = useState(false);
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);

  // Función para alternar la apertura/cierre del SpeedDial
  // const toggleSpeedDial = () => {
  //   setIsSpeedDialOpen((prevIsOpen) => !prevIsOpen);
  // };

  const toggleSpeedDial = () => {
    if (socialMediaOpen) {
      setSocialMediaOpen(false);
    } else {
      setSocialMediaOpen(true);
    }
  };


  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % 6);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + 6) % 6
    );
  };

  const handleActionClick = (actionLink) => {
    window.open(actionLink, '_blank');
  };


  useEffect(() => {
    const interval = setInterval(handleNext, 5000); // Cambia de imagen cada 5 segundos

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="container-images" style={{ position: 'relative' }}>
      <div className="image-container" >
        <img
          src={images[activeStep].img}
          alt={images[activeStep].label}
          className='imagenes-carrousel'
          style={{ width: '100%', height: '50%', transition: 'opacity 2s ease', opacity: '1' }}
        />

        <div className='button-saltarin'>
          <a href="URL_DEL_DESTINO" class="button">
            <span class="text">AVISO IMPORTANTE!!</span>
            <span class="icon"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" data-icon="paper-plane" width="20px" aria-hidden="true"><path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z" fill="currentColor"></path></svg></span>
          </a>
        </div>

        <div className="image-text">
          <p className="text-tecnologia" style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', color: '#E4E6F1', textAlign: 'center', textTransform: 'uppercase', textShadow: '1px 0px 0px black' }}>Technology is the Future</p>
        </div>

      </div>


      <div className="button-container">
        <Button size="small" onClick={handleBack} className='botonDesplazarIzquierda' style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <KeyboardArrowLeft />
        </Button>
        <Button size="small" onClick={handleNext} className='botonDesplazarDerecha' style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <KeyboardArrowRight />
        </Button>
      </div>

      {isSmallScreen && (
        <div className="social-icons-container">
          <Box sx={{ position: 'absolute', right: '0rem', top: '1rem' }}>
            <SpeedDial
              ariaLabel="SpeedDial example"
              icon={<PiPhonePlusBold style={{ fontSize: '1.2rem' }} />}
              direction="up"
              open={socialMediaOpen}
              onClick={toggleSpeedDial}
              FabProps={{
                style: { backgroundColor: '#15B76C', width: '2.5rem', height: '2.5rem' },
              }}
            // onOpen={() => setSocialMediaOpen(true)}
            // onClose={() => setSocialMediaOpen(false)}

            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  FabProps={{
                    style: { backgroundColor: action.color },
                  }}

                  onClick={() => handleActionClick(action.link)}
                />
              ))}
            </SpeedDial>
          </Box>
        </div>
      )}

      {/* Iconos estáticos para pantallas grandes */}
      {!isSmallScreen && (
        <div className="social-icons-container">
          <Button onClick={() => handleActionClick('https://www.instagram.com/abtecchile/')}>
            <PiInstagramLogoLight className='icon-redes-sociales icon-instagram' />
          </Button>
          <Button onClick={() => handleActionClick('https://www.linkedin.com/company/abtec-chile-ingenieria-spa/')}>
            <PiLinkedinLogo className='icon-redes-sociales icon-linkedim' />
          </Button>
          <Button onClick={() => handleActionClick('https://api.whatsapp.com/send?phone=56968252294')}>
            <PiWhatsappLogoLight className='icon-redes-sociales icon-whatsapp' />
          </Button>
        </div>
      )}
    </div>




  );
}

export default Carrousel;