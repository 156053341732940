import React from 'react';
import Carrousel from '../components/Carrousel';
import Cards from '../components/Cards';
import Footer from '../components/Footer';
import CardNosotros from '../components/CardNosotros';
import NavBar from '../components/Navbar'
import CarrouselTeam from '../components/CarrouselTeam';

function Nosotros() {
  return (
    <div>
        <CardNosotros/>
        <CarrouselTeam />
    </div>
  );
}

export default Nosotros;