import React from 'react';
import Footer from '../components/Footer'
import CardsServicios from '../components/CardsServicios';

function SeccionServicios() {
  return (
    <div>
      <CardsServicios />
    </div>
  );
}

export default SeccionServicios;
