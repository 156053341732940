import React, { useState } from "react";
import FooterCss from '../css/Footer.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function Footer() {
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@gmail\.com$/;

  const API_URL = process.env.NODE_ENV === 'production' ? 'http://abtecci.cl' : 'http://localhost:3300';



  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    mensaje: "",
  });

  const [labelError, setLabelError] = useState({
    nombre: false,
    apellido: false,
    email: false,
    telefono: false,
    mensaje: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Cuando se empieza a inscribir el label desaparece
    if (labelError[name] && value.trim() !== "") {
      setLabelError({
        ...labelError,
        [name]: false,
      });
    }
  };

  const isGmailValid = emailRegex.test(formData.email.toLowerCase());

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar si los campos están llenos
    const isFormValid = Object.values(formData).every((value) => value.trim() !== "");

    if (!isFormValid) {
      // Al menos uno de los campos está vacío, establecer los labels en rojo y mostrar un mensaje de error
      const labelErrorCopy = { ...labelError };
      for (const key in formData) {
        if (formData[key].trim() === "") {
          labelErrorCopy[key] = true;
        } else {
          labelErrorCopy[key] = false;
        }
      }
      setLabelError(labelErrorCopy);
      return;
    }

    // Verificar si el correo electrónico es un Gmail válido
    const emailRegex = /^[a-zA-Z0-9._-]+@gmail\.com$/;
    const isGmailValid = emailRegex.test(formData.email.toLowerCase());

    if (!isGmailValid) {
      // El correo electrónico no es un Gmail válido
      setLabelError({
        ...labelError,
        email: true,
      });
      return;
    }

    // Todos los campos están llenos y el correo es válido, enviar la solicitud
    console.log('prueba');
    fetch(`${API_URL}/enviar-correo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSuccessMessage(data.mensaje);
        setFormData({
          nombre: "",
          apellido: "",
          email: "",
          telefono: "",
          mensaje: "",
        });
        setShowSuccessAlert(true);

        // Ocultar la alerta de éxito después de 3 segundos
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 4000);
      })
      .catch((error) => {
        console.error(error);
        setShowErrorAlert(true);

        // Ocultar la alerta de error después de 3 segundos
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 4000);
      });
  };

  return (
    <footer className="bg-dark text-white">

      <Stack className="alerta" spacing={2} >
        {showSuccessAlert && (
          <Alert variant="filled" severity="success">
            {successMessage}
          </Alert>
        )}
        {showErrorAlert && (
          <Alert variant="filled" severity="error">
            Ocurrio un problema, intentelo nuevamente!
          </Alert>
        )}
      </Stack>


      <div className="container" >
        <div className="footer-container " >
          <div className="column texto-contacto" style={{ textAlign: 'start' }}>
            <h3 className="text-uppercase">¿Necesitas ayuda?</h3>
            <p>
              <span>Contáctate con Nosotros</span>

              Comunícate con nosotros para poder asesorarte mejor, completando el formulario. También puedes escribirnos al contacto@abtecci.cl o llámarnos al +56 9 68252294.
            </p>

            <div className="container-copyright">
              <div>
                <p>©2023 ABTEC CHILE. Todos los derechos reservados.</p>
              </div>
            </div>

          </div>

          <hr className="linea-divisoria" />
          <hr className="linea-divisoria-celulares-tablets" />

          <div className="column columna-formulario-contacto" >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': {
                  m: 1,
                  width: '25ch',
                  '& .MuiInputLabel-root': {
                    color: '#d8d8d8',
                    '&.Mui-focused': {
                      color: '#d8d8d8', // Color blanco cuando está enfocado
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#F8FCF7', // Color del texto dentro del campo de entrada
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor: 'white', // Línea inferior antes del foco
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: 'white', // Línea inferior después del foco
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottomColor: 'white', // Línea inferior al pasar el cursor
                  },
                },
              }}
              noValidate
              autoComplete="off">
              <div>
                <TextField
                  id="standard-multiline-flexible"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  multiline
                  maxRows={4}
                  variant="standard"
                  className={`largo-inputs-normales ${labelError.nombre ? 'label-error' : ''}`}
                  InputLabelProps={{
                    classes: {
                      root: labelError.nombre ? 'label-error' : '',
                      focused: 'Mui-focused',
                    },
                  }}
                  label={`Nombre(s)${labelError.nombre ? ' (requerido)' : ''}`}
                />

                <TextField
                  id="standard-multiline-flexible"
                  label={`Apellido(s)${labelError.apellido ? ' (requerido)' : ''}`}
                  name="apellido"
                  value={formData.apellido}
                  onChange={handleChange}
                  multiline
                  maxRows={4}
                  variant="standard"
                  className={`largo-inputs-normales ${labelError.apellido ? 'label-error' : ''}`}
                  InputLabelProps={{
                    classes: {
                      root: labelError.apellido ? 'label-error' : '',
                      focused: 'Mui-focused',
                    },
                  }}
                />
                <TextField
                  id="standard-multiline-flexible"
                  label={`E-mail${labelError.email ? ' (requerido)' : ''}${formData.email.trim() !== '' && !isGmailValid ? ' (no válido)' : ''}`}
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  multiline
                  maxRows={4}
                  variant="standard"
                  className={`largo-inputs-dobles ${labelError.email ? 'label-error' : ''} ${formData.email.trim() !== '' && !isGmailValid ? 'label-error-red' : ''}`}
                  InputLabelProps={{
                    classes: {
                      root: labelError.email ? 'label-error' : '',
                      focused: 'Mui-focused',
                    },
                  }}
                />


              </div>

              <div >
                <TextField
                  id="standard-multiline-flexible"
                  label={`Teléfono${labelError.telefono ? ' (requerido)' : ''}`}
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  multiline
                  maxRows={4}
                  variant="standard"
                  className={`largo-inputs-normales input-telefono ${labelError.telefono ? 'label-error' : ''}`}
                  InputLabelProps={{
                    classes: {
                      root: labelError.telefono ? 'label-error' : '',
                      focused: 'Mui-focused',
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  id="standard-multiline-flexible"
                  label={`Mensaje${labelError.mensaje ? ' (requerido)' : ''}`}
                  multiline
                  name="mensaje"
                  value={formData.mensaje}
                  onChange={handleChange}
                  maxRows={4}
                  fullWidth
                  variant="standard"
                  className={`largo-inputs-dobles ${labelError.mensaje ? 'label-error' : ''}`}
                  InputLabelProps={{
                    classes: {
                      root: labelError.mensaje ? 'label-error' : '',
                      focused: 'Mui-focused',
                    },
                  }}
                />
              </div>


              <button type="submit" onClick={handleSubmit} className="btn-enviar">
                Enviar
                <div className="arrow-wrapper">
                  <div className="arrow"></div>
                </div>
              </button>

            </Box>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
