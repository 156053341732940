import React from 'react';
import Productos from '../components/Productos';
import Footer from '../components/Footer';
import ImagenRDE from '../img/IMAGEN-MINERA-SERVICIOS.jpg';

function SeccionProductoRDE() {
  return (
    <div>
      <Productos
        imagenSrc={ImagenRDE}
        titulo="Sistema de Registro Eventos de Detenciones de Planta"
        descripcion="Esta valiosa herramienta tiene como objetivo principal ayudar en la detección de eventos en cada área o equipo, junto con la correspondiente identificación de sus causas. Su función es asignar categorías de manera estandarizada mediante la implementación de programas de mantenimiento programado, que abarcan distintos tipos de mantenimiento, como mecánico y eléctrico, entre otros."
      />
    </div>
  );
}

export default SeccionProductoRDE;
