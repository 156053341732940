// import React, { useState } from 'react';
// import ImagenUser from '../img/team1.jpg';
// import ImagenUser2 from '../img/team2.jpg';
// import ImagenUser3 from '../img/team3.jpg';
// import CarrouselTeamCss from '../css/CarrouselTeam.css';

// function CarrouselTeam() {
//   const testimonios = [
//     {
//       nombre: 'Especialista 1',
//       texto: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo, veritatis, similique! Ipsa quam vel repellendus, autem fugiat doloribus? Quam, velit.',
//       imagen: ImagenUser
//     },
//     {
//       nombre: 'Especialista 2',
//       texto: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae ad hic, consectetur excepturi vero odio quae eos ratione ipsum aliquid.',
//       imagen: ImagenUser2
//     },
//     {
//       nombre: 'Especialista 3',
//       texto: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non voluptate provident eum, itaque nihil. Minus eius nostrum, veritatis eos eum.',
//       imagen: ImagenUser3
//     },
//   ];

//   const [currentIndex, setCurrentIndex] = useState(0);

//   const handlePrevClick = () => {
//     setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonios.length - 1 : prevIndex - 1));
//   };

//   const handleNextClick = () => {
//     setCurrentIndex((prevIndex) => (prevIndex === testimonios.length - 1 ? 0 : prevIndex + 1));
//   };

//   return (
//     <div className="wrapper text-color">
//       <div className="slider-testimonial">
//         <div className="testimonial-item">
//           <div className="testimonial-client">
//             <img src={testimonios[currentIndex].imagen} alt="imagen-usuario" className="user-logo" />
//             <p className="client-name">{testimonios[currentIndex].nombre}</p>
//           </div>
//           <div className="texto-testimonio">
//             <p>{testimonios[currentIndex].texto}</p>
//           </div>
//         </div>
//         <div className="prev-arrow" onClick={handlePrevClick}>
//           &lt;
//         </div>
//         <div className="next-arrow" onClick={handleNextClick}>
//           &gt;
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CarrouselTeam;



import React from "react";
import ImagenUser from '../img/team1.jpg';
import ImagenUser2 from '../img/team2.jpg';
import ImagenUser3 from '../img/team3.jpg';
import CarrouselTeamCss from '../css/CarrouselTeam.css';
import ImagenGerenteOperaciones from '../img/ImagenGerenteOperaciones.jpg';

function CarrouselTeam() {
  return (
    <div style={{ backgroundColor: '#051C2E' }}>
      <div className="contenedor-team" style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
        <div className="fila-cards-team">
          <div className="columna-cards-team">

            <div className="card">
              <div className="top" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img className="tamanio-imagen-nosotros-gerente" src={ImagenGerenteOperaciones} alt='imagen' />
              </div>
              <p className="desc">
                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>GERENTE DE OPERACIONES </span><br /><br /><br />
                Pedro Gonzalez Salfate<br />
                pedro.gonzalez@abtecci.cl
              </p>
            </div>

          </div>

          <div className="columna-cards-team">

            <div className="card">
              <div className="top">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20 5H4V19L13.2923 9.70649C13.6828 9.31595 14.3159 9.31591 14.7065 9.70641L20 15.0104V5ZM2 3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"></path></svg>

                <p className="title"></p>

              </div>
              <p className="desc">
                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>LIDER ESPECIALISTA EN DESARROLLO E INTEGRACIÓN </span><br /><br />
                Dante Flores Miranda<br />
                dante.flores@abtecci.cl

              </p>
            </div>


          </div>

          <div className="columna-cards-team">

            <div className="card">
              <div className="top">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20 5H4V19L13.2923 9.70649C13.6828 9.31595 14.3159 9.31591 14.7065 9.70641L20 15.0104V5ZM2 3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"></path></svg>

                <p className="title"></p>

              </div>
              <p className="desc">
                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>ESPECIALISTA EN DESARROLLO E INTEGRACIÓN </span><br /><br />
                Eduardo Salinas Ordenes<br />
                eduardo.salinas@abtecci.cl

              </p>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
}

export default CarrouselTeam;
