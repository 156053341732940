import React from "react";
import CardsServiciosCss from '../css/CardsServicios.css';
import IconProgramador from '../img/icon-programador.png';
import IconReportabilidad from '../img//icon-reportabilidad.png';
import IconAdministracionCloud from '../img/[removal.ai]_680f057b-aa93-4e41-8e31-8c75206fc85f-wavy_tech-02_single-10.png';
import IconIntegracionDatos from '../img/363-removebg-preview.png';
import IconBaseDato from '../img/6543131-removebg-preview.png';
import IconSoporteTi from '../img/[removal.ai]_45623731-83b3-4f5e-93b5-ae30cdf83cd8-wavy_tech-20_single-08.png';

function CardsServicios() {
	return (
		<div className="courses-container" style={{ backgroundColor: '#05253e', paddingTop: '3rem' }}>
			<div className="course">
				<div className="course-preview">
					<img src={IconReportabilidad} className="img-card-servicios" style={{width: '10rem', height: '10rem' }} />
				</div>
				<div className="course-info">
					<h3>Reportabilidad Automatizada</h3>
					<p className='parrafo-card-servicios'>
						Simplifique su proceso de generación de informes con nuestra solución de reportabilidad automatizada. Obtenga datos precisos y actualizados en un abrir y cerrar de ojos.
					</p>
				</div>
			</div>

			<div className="course">
				<div className="course-info">
					<h3>Desarrollo de Aplicaciones</h3>
					<p className='parrafo-card-servicios'>
						Creamos aplicaciones a medida para satisfacer sus necesidades comerciales. Desde aplicaciones web hasta móviles, le brindamos soluciones personalizadas de alta calidad.
					</p>
				</div>
				<div className="imagen-card-servicios-derecha">
					<img src={IconProgramador} className="img-card-servicios" style={{ width: '8rem', height: '7.5rem' }} />
				</div>
			</div>

			<div className="course">
				<div className="course-preview">
					<img src={IconAdministracionCloud} className="img-card-servicios img-card-servicios-cloud" style={{ width: '10rem', height: '10rem' }} />
				</div>
				<div className="course-info">
					<h3>Administración de Cloud</h3>
					<p className='parrafo-card-servicios'>
						Gestionamos sus recursos en la nube para que pueda centrarse en su negocio. Garantizamos un rendimiento óptimo y una seguridad robusta en su entorno de nube.
					</p>
				</div>
			</div>

			<div className="course">
				<div className="course-info">
					<h3>Integración de Datos</h3>
					<p className='parrafo-card-servicios'>
						Conectamos sus sistemas y aplicaciones para que los datos fluyan sin problemas. Obtenga una visión completa y actualizada de su negocio con nuestra integración de datos eficiente.
					</p>
				</div>
				<div className="imagen-card-servicios-derecha">
					<img src={IconIntegracionDatos} className="img-card-servicios" style={{ width: '8rem', height: '8rem' }} />
				</div>
			</div>

			<div className="course">
				<div className="course-preview">
					<img src={IconBaseDato} className="img-card-servicios img-card-servicios-base-dato" style={{ width: '10rem', height: '10rem' }} />
				</div>
				<div className="course-info">
					<h3>Desarrollo de Base de Datos</h3>
					<p className='parrafo-card-servicios'>
						Diseñamos y mantenemos bases de datos sólidas y escalables para sus necesidades de almacenamiento y análisis de datos. Garantice la integridad y la disponibilidad de sus datos críticos.
					</p>
				</div>
			</div>

			<div className="course" style={{ marginBottom: '7rem' }}>
				<div className="course-info">
					<h3>Desarrollo y Soporte PI System</h3>
					<p className='parrafo-card-servicios'>
						Maximice el rendimiento de su PI System con nuestro desarrollo y soporte experto. Optimice la captura y análisis de datos para tomar decisiones informadas.
					</p>
				</div>
				<div className="imagen-card-servicios-derecha">
					<img src={IconSoporteTi} className="img-card-servicios" style={{ width: '8rem', height: '8rem' }} />
				</div>
			</div>
		</div>
	);
}

export default CardsServicios;
