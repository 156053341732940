// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importa Route y Routes
// import Home from './pages/Home';
// import Navbar from './components/Navbar';
// import Nosotros from './pages/Nosotros'; // Importa la página "Nosotros" si aún no lo has hecho

// function App() {
//   return (
//     <div className="App">
//       <Router>
//         <Navbar />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/nosotros" element={<Nosotros />} /> {/* Agrega la ruta para "Nosotros" */}
//           {/* Agrega más rutas para otras páginas si es necesario */}
//         </Routes>
//       </Router>
//     </div>
//   );
// }

// export default App;



// import './App.css';
// import Navbar from './components/Navbar';
// import Carrousel from './components/Carrousel';
// import Cards from './components/Cards';
// import Footer from './components/Footer';
// import CardNosotros from './components/CardNosotros';
// import CarrouselTeam from './components/CarrouselTeam';


// function App() {
//   return (
//     <div className="App">

//       <Navbar />

//       {/* <CardNosotros />

//       <CarrouselTeam /> */}

//       <Carrousel />

//       <Cards />

//       <Footer />






//       {/* <div
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           height: '100vh',
//         }}
//       >
//         <h2
//           style={{
//             transform: 'rotate(-30deg)',
//             transformOrigin: 'center center',
//             textAlign: 'center',
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//             color: 'white',
//             padding: '2rem',
//             fontSize: '1.5rem',
//             boxShadow: '5px 5px 10px #242424'
//           }}
//         >
//           EN CONSTRUCCIÓN
//         </h2>
//       </div> */}






//       {/* <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header> */}
//     </div>
//   );
// }


// export default App;







// import React from 'react';
// import Navbar from './components/Navbar';
// import AppRouter from './routes/AppRouter';

// function App() {
//   return (
//     <div className="App">
//       <Navbar />
//       <AppRouter />
//     </div>
//   );
// }

// export default App;






// import React, { useState } from 'react';
// import Navbar from './components/Navbar';
// import Home from './pages/Home';
// import Nosotros from './pages/Nosotros';

// function App() {
//   const [contenido, setContenido] = useState('home');

//   const navegarA = (seccion) => {
//     setContenido(seccion);
//   };

//   return (
//     <div className="App">
//       <Navbar navegar={navegarA} /> 

//       {contenido === 'home' && <Home />}
//       {contenido === 'nosotros' && <Nosotros />}
//     </div>
//   );
// }

// export default App;






import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Nosotros from './pages/Nosotros';
import SeccionNoticias from './pages/SeccionNoticias';
import SeccionServicios from './pages/SeccionServicios';
import SeccionProductoHRS from './pages/SeccionProductoHRS';
import SeccionProductoRDE from './pages/SeccionProductoRDE';
import SeccionProductoSTPA from './pages/SeccionProductoSTPA';

function App() {
  const [contenido, setContenido] = useState('home');

  useEffect(() => {
    const rutaActual = window.location.pathname;

    // Actualizamos el estado 'contenido' según la ruta actual
    if (rutaActual === '/') {
      setContenido('home');
    } else if (rutaActual === '/nosotros') {
      setContenido('nosotros');
    } else if (rutaActual === '/noticias') { 
      setContenido('noticias');
    } else if (rutaActual === '/servicios') { 
      setContenido('servicios');
    } else if (rutaActual === '/productoHRS') { 
      setContenido('productoHRS');
    } else if (rutaActual === '/productoRDE') { 
      setContenido('productoRDE');
    } else if (rutaActual === '/productoSTPA') { 
      setContenido('productoSTPA');
    }


  }, []);

  const navegarA = (seccion) => {
    setContenido(seccion);

    // Cambiamos la URL cuando se hace clic en una opción
    if (seccion === 'home') {
      window.history.pushState(null, null, '/');
    } else if (seccion === 'nosotros') {
      window.history.pushState(null, null, '/nosotros');
    } else if (seccion === 'noticias') { 
      window.history.pushState(null, null, '/noticias');
    } else if (seccion === 'servicios') { 
      window.history.pushState(null, null, '/servicios');
    } else if (seccion === 'productoHRS') { 
      window.history.pushState(null, null, '/productoHRS');
    } else if (seccion === 'productoRDE') { 
      window.history.pushState(null, null, '/productoRDE');
    } else if (seccion === 'productoSTPA') { 
      window.history.pushState(null, null, '/productoSTPA');
    }

  };

  return (
    <div className="App">
      <Navbar navegar={navegarA} />

      {contenido === 'home' && <Home />}
      {contenido === 'nosotros' && <Nosotros />}
      {contenido === 'noticias' && <SeccionNoticias />}
      {contenido === 'servicios' && <SeccionServicios />}
      {contenido === 'productoHRS' && <SeccionProductoHRS />}
      {contenido === 'productoRDE' && <SeccionProductoRDE />}
      {contenido === 'productoSTPA' && <SeccionProductoSTPA />}
      
    </div>
  );
}

export default App;
