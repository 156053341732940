import React, { useState, useEffect } from 'react';
import NavbarCss from '../css/Navbar.css';
import { AiOutlineHome, AiOutlineForm, AiOutlinePhone, AiOutlineFileDone, AiOutlineBars, AiOutlineClose, AiOutlineFolderOpen, AiOutlineDown } from 'react-icons/ai';
import { IoNewspaperOutline } from "react-icons/io5";
import Logo from '../img/logo-empresa.png';

function Navbar({ navegar }) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [mostrarSubItemCelulares, setMostrarSubItemCelulares] = useState(false);

  const toggleMostrarSubItemCelulares = () => {
    setMostrarSubItemCelulares(!mostrarSubItemCelulares);
  };

  const handleProductosClick = () => {
    setMostrarSubItemCelulares(!mostrarSubItemCelulares);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  //Cierra el menú cuando se hace clic en una opción
  const cerrarMenu = () => {
    setMenuVisible(false);
    setMostrarSubItemCelulares(false);
  };

  const cambiarURL = (ruta) => {
    window.history.pushState(null, null, ruta);
  };



  return (
    <nav className="navbar">
      <div className="left">
        <img src={Logo} alt="Logo" className="logo girar-logo-empresa" />
        <span className='nombre-empresa'>ABTEC CHILE</span>
      </div>
      <div className="right">
        <button className={`nav-toggle ${menuVisible ? 'active' : ''}`} onClick={toggleMenu}>
          {menuVisible ? (
            <span className="close-icon">+</span>
          ) : (
            <>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </>
          )}
        </button>

        <ul className={`nav-list ${menuVisible ? 'show' : ''}`}>
          <li className="nav-item" onClick={() => { navegar('home'); cerrarMenu(); }}>
            <AiOutlineHome className="nav-icon" />
            <a>Inicio</a>
          </li>
          <li className="nav-item" onClick={() => { navegar('nosotros'); cerrarMenu(); }}>
            <AiOutlineForm className="nav-icon" />
            <a>Nosotros</a>
          </li>
          <li className="nav-item">
            <AiOutlineFolderOpen className="nav-icon" />
            <a onClick={handleProductosClick}>Productos <AiOutlineDown className="nav-icon nav-icon-flecha-productos" /></a>



            {mostrarSubItemCelulares && (
              <ul className='prueba'>
                <li onClick={() => { navegar('productoHRS'); cerrarMenu(); }}>
                  <a>HRS</a>
                </li>
                <li onClick={() => { navegar('productoRDE'); cerrarMenu(); }}>
                  <a>RDE</a>
                </li>
                <li onClick={() => { navegar('productoSTPA'); cerrarMenu(); }}>
                  <a>STPA</a>
                </li>
              </ul>
            )}




            <ul class="menu-vertical  slide-left">
              <li onClick={() => { navegar('productoHRS'); cerrarMenu(); }}>
                <a>(HRS) Historiador de Reportabilidad</a>
              </li>
              <li onClick={() => { navegar('productoRDE'); cerrarMenu(); }}>
                <a>(RDE) Registro de Dentenciones Eventos de Planta</a>
              </li>
              <li onClick={() => { navegar('productoSTPA'); cerrarMenu(); }}>
                <a>(STPA) Sistema Toma de Pozos y Análisis</a>
              </li>
            </ul>
          </li>
          <li className="nav-item" onClick={() => { navegar('servicios'); cerrarMenu(); }}>
            <AiOutlineFileDone className="nav-icon" />
            <a>Servicios</a>
          </li>
          <li className="nav-item noticias-text-nav" >
            {/* onClick={() => { navegar('noticias'); cerrarMenu(); }} */}
            <IoNewspaperOutline className="nav-icon" />
            <a>Noticias</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
