import React from "react";
import ImagenNosotros from '../img/icon-programador.png';
import CardNosotrosCss from '../css/CardNosotros.css';
import Imagen from '../img/NOSOTROS-IMAGEN-PRINCIPAL.png';
import ImagenDeFondo from '../img/posible-fondo1.jpg';

function CardNosotros() {
	return (
		<div className="container-nosotros" style={{ backgroundImage: `url(${ImagenDeFondo})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
			<div className="container-card-nosotros " >
				<div className="columna-nosotros">
					<h1 className="mbr-section-title mbr-fonts-style mb-4 display-2 text-color" style={{ color: 'white' }}>
						<p className="titulo-quienes-somos">Quiénes somos</p>
					</h1>
					<span className="texto-nosotros-empresa" style={{ color: 'white' }}>Somos una empresa experta en tecnología y creatividad, resolviendo desafíos digitales complejos. Ofrecemos soluciones personalizadas de software, consultoría avanzada en TI y asesoramiento estratégico en transformación digital.</span>
				</div>

				<div className="" >

					<img alt='imagen-nosotros ' src={Imagen} className="imagen-nosotros" />

				</div>
			</div>
		</div>
	)
}


export default CardNosotros;