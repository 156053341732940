import React from "react";
import Noticias from '../components/Noticias.js';

function SeccionNoticias() {
    return (
      <div>
        <Noticias/>
      </div>
    );
  }
  
  export default SeccionNoticias;