import React from 'react';
import Productos from '../components/Productos';
import Footer from '../components/Footer';
import ImagenSTPA from '../img/ImagenProductoSTPA.jpg';

function SeccionProductoSTPA() {
  return (
    <div>
      <Productos
        imagenSrc={ImagenSTPA}
        titulo="Toma de Pozos y Análisis"
        descripcion='Sistema especializado en la recopilación de diversas variaciones en los pozos en terreno, integrándose eficientemente con los análisis de laboratorio. La información recopilada se utiliza para formar secuencias detalladas de datos, con intervalos de tiempo específicos, que son empleados para visualizar indicadores en informes automatizados, simplificando así el proceso de toma de decisiones.'
      />
    </div>
  );
}

export default SeccionProductoSTPA;
